import { i18n } from "../translations/i18n"

export const LANG = {
  pt: "pt",
  en: "en"
} 

export const Languages = [
  {
    value:"pt",
    title:i18n.t("constants.languages.portuguese")
  },
  {
    value:"en",
    title:i18n.t("constants.languages.english")
  }
]