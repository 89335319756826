import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import * as Styled from "../styles";
import { i18n } from "../../../translations/i18n";
import { useNavigate } from "react-router";
import { ROUTE_PATHS } from "../../../constants/routes";
import InstaAvatar from "../../../components/InstaAvatar";

const Third = () => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-end"
      justifyContent="center"
      style={{ minHeight: "100%" }}
    >
              <Box display="flex" alignItems="center" columnGap={1}>
          <Styled.STypography>
            {i18n.t("footer.third.first")}
          </Styled.STypography>
          <a
            href="https://www.instagram.com/instabolina" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ textDecoration: "none" }} 
          >
            <InstaAvatar backgroundColor="black" color="white" />
          </a>
        </Box>
    </Grid>
  );
};

export default Third;
