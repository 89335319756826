export const Colors = {
  tealcTransparent: "#009ca666",
  tealc: "#009ca6 ",
  tealcDark: "#324B4D",
  turqouise: "#4dbecd",
  roastedYellow: "#e1d4ac",
  champagne: "#ece1c6",
  dirtyWhite: "#F4F6F7 ",
  darkGrey: "#3b3b3b",
  grey: "#e4e4e4",
  greyTransparent: "#e4e4e466",
  brilho: "#ffe500",
};

export const Pallette = {
  primary: Colors.tealc,
  primaryTransparent: Colors.tealcTransparent,
  primaryDark: Colors.tealcDark,
  constrast: "white",
  constrastTransparent: Colors.greyTransparent,
  text: Colors.darkGrey,
  textTransparent: Colors.greyTransparent,
};
