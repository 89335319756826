import { Box, Grid } from "@mui/material";
import { i18n } from "../../../translations/i18n";
import * as Styled from "../styles";
import InstaAvatar from "../../../components/InstaAvatar";

const Second = () => {
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-end"
      justifyContent="right"
      style={{ minHeight: "100%" }}
    >
        <Styled.STypography>contacto@abolina.pt</Styled.STypography>
        
      
      
    </Grid>
  );
};

export default Second;
